import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from 'features/login/login';
import { api } from './api';
import alertsReducer from 'features/alerts/alertsSlice';
import videoReducer from 'features/videos/videosSlice';
import imageReducer from 'features/images/imagesSlice';

export const store = configureStore({
  reducer: {
    alerts: alertsReducer,
    auth: loginReducer,
    videos: videoReducer,
    images: imageReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
