import { Answer } from 'app/types';
import { api } from 'app/api';

export interface AddAnswer {
  answer: string;
  order: number;
  type: string;
  questionId: number;
}

export interface UpdateAnswer {
  id: number;
  order: number;
  type: string;
  answer: string;
}

export const answersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addAnswer: builder.mutation<Answer, AddAnswer>({
      query: (params) => ({
        url: 'admin/answers',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Question'],
    }),
    updateAnswer: builder.mutation<Answer, UpdateAnswer>({
      query: (params) => ({
        url: `admin/answers/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Question'],
    }),
    deleteAnswer: builder.mutation<Answer, { id: number }>({
      query: (params) => ({
        url: `admin/answers/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Question'],
    }),
  }),
})

export const {
  useAddAnswerMutation,
  useUpdateAnswerMutation,
  useDeleteAnswerMutation,
} = answersApi;
