import React from 'react';
import { Outlet } from 'react-router-dom';
import { NavBar } from '../';

export const Layout: React.FC = () => {
    return (
        <React.Fragment>
            <NavBar />
            <Outlet />
        </React.Fragment>
    );
};
