import { FormControl, Input, Grid, Button } from '@mui/material';
import { useAppDispatch } from 'app/hooks';
import { dispatchAlert } from 'features/alerts/alertsSlice';
import { useDeleteImageMutation } from 'features/images/imagesApi';
import { addImage } from 'features/images/imagesSlice';
import React from 'react';

interface ImageViewProps {
	image: any;
	tabletID: string;
}

export const ImageView: React.FC<ImageViewProps> = ({
	image,
	tabletID,
}: ImageViewProps) => {
	const inputRef = React.useRef<any>(null);
	const [imageFile, setImageFile] = React.useState<File | null>(null);

	const [deleteImage, resultDeleteImage] = useDeleteImageMutation();

	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (resultDeleteImage.error) {
			const { code, message } = (resultDeleteImage.error as any).data;

			dispatch(
				dispatchAlert({
					type: 'error',
					code,
					message,
				},
			));
		}
}, [resultDeleteImage.error]);

	const handleSaveImage = React.useCallback(() => {
		const data = new FormData();
		data.append('file', imageFile);
		data.append('userId', tabletID);

		dispatch(addImage(data));
	}, [imageFile, tabletID]);

	const onFileChange = React.useCallback((event: any) => {
		setImageFile(event.target.files[0]);
	}, []);

	const handleClearFileInput = React.useCallback(() => {
		setImageFile(null);
		deleteImage({ id: image.id });
		inputRef.current.value = null;
	}, [image]);

	return (
		<Grid container={true} spacing={3} style={{ padding: 32 }}>
			{image && (
				<Grid item={true} xs={12} sm={12} style={{ textAlign: 'center' }}>
					<img src={image.file.url} alt="reserve-image" style={{ width: '676px' }} />
				</Grid>
			)}
			<Grid item={true} xs={12} sm={12} style={{ textAlign: 'center' }}>
				<FormControl>
					<Input
							id="file-image-input"
							type="file"
							onChange={onFileChange}
							inputProps={{ accept: '.jpg,.jpeg,.png'}}
							inputRef={inputRef}
					/>
					<Grid container={true} spacing={1}>
						<Grid item={true} xs={12} sm={6}>
							<Button
								variant="contained"
								fullWidth={true}
								onClick={handleClearFileInput}
								color="error"
								disabled={image === null}
							>
								Reset
							</Button>
						</Grid>
						<Grid item={true} xs={12} sm={6}>
							<Button
								variant="contained"
								fullWidth={true}
								onClick={handleSaveImage}
								disabled={image !== null}
							>
								Save
							</Button>
						</Grid>
					</Grid>
				</FormControl>
			</Grid>
		</Grid>
	);
};
