import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Navigation } from 'router';
import { Alerts } from 'containers';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Alerts />
            <Navigation />
        </BrowserRouter>
    );
}

export default App;
