import {
  Box,
  Button,
  CircularProgress,
  Paper,
  styled,
  Grid,
  Typography,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from '@mui/material';
import React from 'react';
import { useGetTabletQuery, useUpdateTabletMutation } from 'features/tablets/tabletsApi';
import { useParams } from 'react-router-dom';
import { CardComponent, TabsComponent, ModalComponent } from 'components';
import { VideosOfTablet } from 'containers/Tablets/Videos';
import { QuestionnairesOfTablet } from 'containers/Tablets/Questionnaires';
import { ConfigurationsOfTablet } from 'containers/Tablets/Configurations';
import { ActivitiesOfTablet } from 'containers/Tablets/Activity';
import { ImageView } from 'containers/Tablets/Image';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from 'app/hooks';

const CustomPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

const CustomBox = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

const modalIconStyle = {
    position: 'absolute' as 'absolute',
    top: '0',
    right: '0',
};

export const Tablet: React.FC = () => {
    const params = useParams();
    const shouldRefetchVideo = useAppSelector(state => state.videos.shouldRefetch);
    const shouldRefetchUser = useAppSelector(state => state.auth.shouldRefetch);
    const shouldRefetchImage = useAppSelector(state => state.images.shouldRefetch);
    const { data, isLoading, refetch } = useGetTabletQuery(+(params.tabletID || '-1'));
    const [updateTablet] = useUpdateTabletMutation();
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [role, setRole] = React.useState<string>('');

    React.useEffect(() => {
      if (data && !role) {
        setRole(data.role);
      }
    }, [data]);

    React.useEffect(() => {
        if (shouldRefetchVideo || shouldRefetchUser || shouldRefetchImage) {
            refetch();
        }
    }, [shouldRefetchVideo, shouldRefetchUser, shouldRefetchImage]);

    const handleOpenModal = React.useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleModalClose = React.useCallback(() => {
        setModalOpen(false);
    }, []);

    const handleModalConfirm = React.useCallback(() => {
        if (data) {
          updateTablet({
            id: data.id,
            role,
          })
        }

        handleModalClose();
    }, [data, role]);

    const handleChangeRole = React.useCallback((event: any) => {
      setRole(event.target.value);
    }, [role]);

    const renderModalHead = React.useMemo(() => {
        return (
            <Grid container={true}>
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-around' }}>
                    <Typography
                        color="inherit"
                        variant="h6"
                        component="div"
                    >
                        Edit Tablet
                        <IconButton style={modalIconStyle} onClick={handleModalClose}>
                            <CloseIcon>Close</CloseIcon>
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
        );
    }, []);

    const renderModalBody = React.useMemo(() => {
        return (
            <Grid container={true} direction="column">
                <Grid container={true} direction="column">
                    <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                      <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                        <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={role}
                          label="Role"
                          onChange={handleChangeRole}
                          autoWidth={false}
                        >
                          <MenuItem value={'member'}>Member</MenuItem>
                          <MenuItem value={'admin'}>Admin</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                </Grid>
                <Grid container={true} spacing={4} justifyContent="center" alignItems="center">
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="error"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalConfirm}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [role]);

    const renderModal = React.useMemo(() => {
        return (
            <ModalComponent
                open={modalOpen}
                handleClose={handleModalClose}
                modalHead={renderModalHead}
                modalBody={renderModalBody}
            />
        );
    }, [modalOpen, renderModalHead, renderModalBody]);


    const renderTabletInfo = React.useMemo(() => {
        return (
            <CustomBox>
                <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12} sm={6} md={4}>
                        <CardComponent
                            id="id"
                            label="Account ID"
                            value={data?.id || ''}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={4}>
                        <CardComponent
                            id="username"
                            label="Username"
                            value={data?.username || ''}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={4}>
                        <CardComponent
                            id="role"
                            label="Role"
                            value={data?.role || ''}
                            onClick={handleOpenModal}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={4}>
                        <CardComponent
                            id="createdAt"
                            label="Created At"
                            value={data?.createdAt || ''}
                        />
                    </Grid>
                </Grid>
            </CustomBox>
        );
    }, [data]);

    const tabs = React.useMemo(() => {
        return [
            {
                label: 'Videos',
                panel: <VideosOfTablet videos={data?.videos || []} />,
            },
            {
                label: 'Questionnaires',
                panel: <QuestionnairesOfTablet questionnaires={data?.questionnaires || []} />,
            },
            {
                label: 'Configurations',
                panel: <ConfigurationsOfTablet configs={data?.configs || []} />,
            },
            {
                label: 'Activity',
                panel: <ActivitiesOfTablet activities={data?.activities || []} />,
            },
            {
                label: 'Image',
                panel: <ImageView image={data?.images.length ? data.images[0] : null} tabletID={params.tabletID} />,
            }
        ]
    }, [data, params.tabletID]);

    const renderTabs = React.useMemo(() => {
        return <TabsComponent tabs={tabs} />;
    }, [tabs]);

    const renderContent = React.useMemo(() => {
        if (isLoading) {
            return <CircularProgress />;
        }

        if (!data) {
            return (
                <CustomPaper elevation={4}>
                    <Typography variant="h5" component="div">
                        User with id {params.tabletID} does not exist
                    </Typography>
                </CustomPaper>
            );
        }

        return (
            <React.Fragment>
                {renderTabletInfo}
                <CustomPaper elevation={4}>
                    {renderTabs}
                </CustomPaper>
                {renderModal}
            </React.Fragment>
        );
    }, [renderTabletInfo, renderTabs, modalOpen, renderModal, data]);

    return renderContent;
};
