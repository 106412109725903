import { useAddAnswerMutation, useUpdateAnswerMutation, useDeleteAnswerMutation } from 'features/answers/answersApi';
import { TableComponent } from 'components/Table';
import { Box, Button, CircularProgress, Grid, IconButton, Paper, styled, TextField, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { CardComponent, ModalComponent } from 'components';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useGetQuestionQuery } from 'features/questions/questionsApi';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { dispatchAlert } from 'features/alerts/alertsSlice';


const CustomPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
}));

const CustomBox = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

const modalIconStyle = {
    position: 'absolute' as 'absolute',
    top: '0',
    right: '0',
};

export const GeneralAnswers: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [answer, setAnswer] = React.useState<string>('');
    const [type, setType] = React.useState<string>('');
    const [order, setOrder] = React.useState<number>(0);
    const [selectedAnswer, setSelectedAnswer] = React.useState<number | null>(null);
    const [modalMode, setModalMode] = React.useState<string>('');

    const [addAnswer, resultAddAnswer] = useAddAnswerMutation();
    const [updateAnswer, resultUpdateAnswer] = useUpdateAnswerMutation();
    const [deleteAnswer, resultDeleteAnswer] = useDeleteAnswerMutation();

    const dispatch = useAppDispatch();

    const { questionId } = useParams();
    const { data, isLoading, error } = useGetQuestionQuery({ id: questionId || '' });

    React.useEffect(() => {
        if (error) {
            const { code, message } = (error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [error]);

    React.useEffect(() => {
        if (resultAddAnswer.error) {
            const { code, message } = (resultAddAnswer.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultAddAnswer.error]);

    React.useEffect(() => {
        if (resultUpdateAnswer.error) {
            const { code, message } = (resultUpdateAnswer.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultUpdateAnswer.error]);

    React.useEffect(() => {
        if (resultDeleteAnswer.error) {
            const { code, message } = (resultDeleteAnswer.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultDeleteAnswer.error]);

    const handleOpenModal = React.useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleModalClose = React.useCallback(() => {
        setAnswer('');
        setOrder(0);
        setType('');
        setSelectedAnswer(null);
        setModalOpen(false);
    }, []);

    const handleChangeAnswer = React.useCallback((event) => {
        setAnswer(event.target.value);
    }, []);

    const handleChangeOrder = React.useCallback((event) => {
        setOrder(event.target.value);
    }, []);

    const handleChangeType = React.useCallback((event) => {
        setType(event.target.value);
    }, []);

    const handleClickAddAnswer = React.useCallback(() => {
        handleOpenModal();
        setModalMode('add');
    }, []);

    const handleClickEditAnswer = React.useCallback((answerItem) => {
        setSelectedAnswer(answerItem.id);
        handleOpenModal();
        setModalMode('edit');
        setAnswer(answerItem.answer);
    }, []);

    const handleModalConfirm = React.useCallback(() => {
        if (questionId) {
            switch (modalMode) {
                case 'add':
                    addAnswer({
                        answer,
                        order,
                        type,
                        questionId: +questionId,
                    });
                    break;
                case 'edit':
                    if (selectedAnswer) {
                        updateAnswer({
                            id: selectedAnswer,
                            answer,
                            order,
                            type,
                        });
                    }
                    break;
                default:
            }
        }

        handleModalClose();
    }, [answer, order, type, selectedAnswer, questionId]);

    const handleDeleteAnswer = React.useCallback((answer) => {
        deleteAnswer({ id: answer.id });
    }, []);

    const tableColumns = React.useMemo(() => {
        return [
            {
                id: 'id',
                label: 'Answer ID',
                minWidth: 70,
            },
            {
                id: 'answer',
                label: 'Answer',
                minWidth: 170,
            },
            {
                id: 'order',
                label: 'Order',
                minWidth: 70,
            },
            {
                id: 'count',
                label: 'Count',
                minWidth: 70,
            },
            {
                id: 'type',
                label: 'Type',
                minWidth: 70,
            },
            {
                id: 'createdAt',
                label: 'Created At',
                minWidth: 150,
                align: 'right',
            },
            {
                id: 'updatedAt',
                label: 'Updated At',
                minWidth: 150,
                align: 'right',
            },
            {
                id: 'actions',
                label: '',
                minWidth: 150,
                align: 'right',
            },
        ];
    }, []);

    const renderContent = React.useMemo(() => {
        if (isLoading) {
            return <CircularProgress />;
        }

        return (
            <TableComponent
                showPagination={true}
                columns={tableColumns}
                data={data?.answers || []}
                handleEdit={handleClickEditAnswer}
                handleDelete={handleDeleteAnswer}
            />
        );
    }, [data?.answers, isLoading, tableColumns]);

    const renderModalHead = React.useMemo(() => {
        return (
            <Grid container={true}>
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-around' }}>
                    <Typography
                        color="inherit"
                        variant="h6"
                        component="div"
                    >
                        Add Answer
                        <IconButton style={modalIconStyle} onClick={handleModalClose}>
                            <CloseIcon>Close</CloseIcon>
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
        );
    }, []);

    const renderModalBody = React.useMemo(() => {
        return (
            <Grid container={true} direction="column">
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                    <TextField
                        label="Answer"
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                        value={answer}
                        onChange={handleChangeAnswer}
                    />
                </Grid>
                <Grid container={true} direction="column">
                    <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                        <TextField
                            label="Order"
                            variant="outlined"
                            fullWidth={true}
                            size="small"
                            value={order}
                            onChange={handleChangeOrder}
                        />
                    </Grid>
                </Grid>
                <Grid container={true} direction="column">
                    <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                        <TextField
                            label="Type"
                            variant="outlined"
                            fullWidth={true}
                            size="small"
                            value={type}
                            onChange={handleChangeType}
                        />
                    </Grid>
                </Grid>
                <Grid container={true} spacing={4} justifyContent="center" alignItems="center">
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="error"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalConfirm}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [answer, order, type]);

    const renderModal = React.useMemo(() => {
        return (
            <ModalComponent
                open={modalOpen}
                handleClose={handleModalClose}
                modalHead={renderModalHead}
                modalBody={renderModalBody}
            />
        );
    }, [modalOpen, renderModalHead, renderModalBody]);

    const renderTitle = React.useMemo(() => {
        return (
            <CustomToolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    Answers
                </Typography>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    align="right"
                >
                    <IconButton onClick={handleClickAddAnswer}>
                        <AddIcon />
                    </IconButton>
                    Add Answer
                </Typography>
            </CustomToolbar>
        );
    }, []);

    const renderQuestionInfo = React.useMemo(() => {
        if (isLoading) {
            return <CircularProgress />;
        }

        return (
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <CardComponent
                        id="id"
                        label="Question ID"
                        value={data?.id || ''}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <CardComponent
                        id="question"
                        label="Question"
                        value={data?.question || ''}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <CardComponent
                        id="createdAt"
                        label="Created At"
                        value={data?.createdAt || ''}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <CardComponent
                        id="updatedAt"
                        label="Updated At"
                        value={data?.updatedAt || ''}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <CardComponent
                        id="userId"
                        label="Questionnaire's ID"
                        value={data?.questionnaire.id || ''}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <CardComponent
                        id="userId"
                        label="Questionnaire's Title"
                        value={data?.questionnaire.title || ''}
                    />
                </Grid>
            </Grid>
        );
    }, [data, isLoading]);

    return (
        <React.Fragment>
            <CustomBox>
                {renderQuestionInfo}
            </CustomBox>
            <CustomPaper elevation={4}>
                {renderTitle}
                {renderContent}
                {renderModal}
            </CustomPaper>
        </React.Fragment>
    );
};
