import { api } from 'app/api';
import { User } from 'app/types';

export interface UserResponse {
  user: User;
}

export const accountsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserResponse, void>({
      query: () => ({
        url: 'user',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetUserQuery } = accountsApi;
