import { api } from 'app/api';
import { User } from 'app/types';

export interface UserResponse {
  user: User;
}

export interface LoginRequest {
  user: {
    username: string;
    password: string;
  };
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/users/login',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
})

export const { useLoginMutation } = authApi;
