import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { localeDate, getUserAgent } from 'helpers';
import { Grid, TableSortLabel, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import SvgIcon from '@mui/material/SvgIcon';

interface TableColumn {
  id: string;
  label: string;
  minWidth: number;
  align?: string;
  sortable?: boolean;
}

type Order = 'asc' | 'desc';

interface TableProps {
  showPagination: boolean;
  columns: TableColumn[];
  data: any[];
  handleEdit?: (obj: any) => void;
  handleDelete?: (id: number) => void;
  handleRowClick?: (id: number) => void;
  orderBy?: string;
  orderSide?: Order;
  handleChangeSortOrderSide?: (field: string, side: Order) => void;
}

export const TableComponent: React.FC<TableProps> = ({
  showPagination,
  columns,
  data,
  handleEdit,
  handleDelete,
  handleRowClick,
  orderBy,
  orderSide,
  handleChangeSortOrderSide,
}: TableProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string | number; }; }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeOrderSide = React.useCallback((field: string, orderSide: string) => {
    if (field !== orderBy) {
      handleChangeSortOrderSide(field, 'asc');
    } else {
      handleChangeSortOrderSide(field, orderSide === 'asc' ? 'desc' : 'asc');
    }
  }, [orderBy, orderSide]);

  const renderTableHeader = React.useMemo(() => {
    return (
      <TableHead>
        <TableRow>
          {columns.map((column) => {
            if (column.sortable) {
              return (
                <TableCell
                  key={column.id}
                  align={column.align as any}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? orderSide : 'desc'}
                    onClick={() => handleChangeOrderSide(column.id, orderSide)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              );
            }

            return (
              <TableCell
                key={column.id}
                align={column.align as any}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }, [columns, handleChangeOrderSide]);

  const renderErrorIcon = React.useCallback((lastActionTime: any) => {
    if (lastActionTime) {
      const now: any = new Date();
      const lastAction: any = new Date(lastActionTime);

      const diffMs = now - lastAction;
      const diffMins = Math.round(diffMs / 60000);

      if (diffMins >= 30) {
        return (
          <SvgIcon color="error" style={{ marginLeft: 20 }}>
            <ErrorIcon />
          </SvgIcon>
        );
      }
    }

    return null;
  }, []);

  const renderCell = React.useCallback((value: any, column: string, row: any) => {
    switch (column) {
      case 'actions':
        return (
          <Grid container={true} spacing={0}>
            <Grid item={true} xs={6}>
              <Typography
                color="primary"
                variant="subtitle2"
                component="span"
                onClick={() => handleEdit?.(row)}
                style={{ cursor: 'pointer' }}
              >
                Edit
              </Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Typography
                color="primary"
                variant="subtitle2"
                component="span"
                onClick={() => handleDelete?.(row)}
                style={{ cursor: 'pointer' }}
              >
                Delete
              </Typography>
            </Grid>
          </Grid>
        );
      case 'videoSource':
        return (
          <Typography
            color="primary"
            variant="subtitle2"
            component="span"
            onClick={() => handleEdit?.(row)}
            style={{ cursor: 'pointer' }}
          >
            {row.file.url}
          </Typography>
        );
      case 'userAgent':
        return (
          <Typography
            color="primary"
            variant="subtitle2"
            component="span"
            onClick={() => handleEdit?.(row)}
            style={{ cursor: 'pointer' }}
          >
            {getUserAgent(value)}
          </Typography>
        );
      default:
    }

    switch (typeof value) {
      case 'boolean':
        const convertedFromBoolean = String(value);
        const capitalizedValue = convertedFromBoolean.charAt(0).toUpperCase() + convertedFromBoolean.slice(1);

        return (
          <Typography
            color="primary"
            variant="subtitle2"
            component="span"
            onClick={() => handleEdit?.(row)}
            style={{ cursor: 'pointer' }}
          >
            {capitalizedValue}
          </Typography>
        );
      case 'number':
        return (
          <Typography
            color="primary"
            variant="subtitle2"
            component="span"
            onClick={() => handleEdit?.(row)}
            style={{ cursor: 'pointer' }}
          >
            {value}
          </Typography>
        );
      case 'string':
        switch (column) {
          case 'createdAt':
          case 'updatedAt':
            return (
              <Typography
                color="primary"
                variant="subtitle2"
                component="span"
                onClick={() => handleEdit?.(row)}
                style={{ cursor: 'pointer' }}
              >
                {localeDate(value, 'fullDate')}
              </Typography>
            );
          case 'lastActionTime':
            return (
              <Typography
                color="primary"
                variant="subtitle2"
                component="span"
                onClick={() => handleEdit?.(row)}
                style={{ cursor: 'pointer', display: 'flex', 'alignItems': 'center', 'justifyContent': 'flex-end'}}
              >
                {localeDate(value, 'fullDate')}
                {renderErrorIcon(value)}
              </Typography>
            );
          default:
            return value;
        }
      default:
        return value;
    }
  }, [renderErrorIcon]);

  const renderTableBody = React.useMemo(() => {
    return (
      <TableBody>
        {data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row: any) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                {columns.map((column: any) => {
                  const value = row[column.id];

                  return (
                    <TableCell key={column.id} align={column.align as any} onClick={() => column.id !== 'actions' && handleRowClick?.(row.id)}>
                      {renderCell(value, column.id, row)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    );
  }, [data, page, rowsPerPage]);

  const renderPagination = React.useMemo(() => {
    if (showPagination) {
      return (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    }
  }, [showPagination, page, rowsPerPage, data]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 676 }}>
        <Table stickyHeader aria-label="sticky table">
          {renderTableHeader}
          {renderTableBody}
        </Table>
      </TableContainer>
      {renderPagination}
    </Paper>
  );
}
