import * as React from 'react';
import { Alert as AlertMU, AlertColor, AlertTitle } from '@mui/material';
import { capitalize } from 'helpers';

type AlertType = 'success' | 'error' |'info' | 'warn';

export interface AlertProps {
    type: AlertType;
    message: string;
    onClose: () => void;
}

export const Alert: React.FC<AlertProps> = ({
    type,
    message,
    onClose,
}: AlertProps) => {
    React.useEffect(() => {
        setTimeout(() => {
            onClose();
        }, 10000);
    }, []);

    return (
        <AlertMU severity={type as AlertColor}>
            <AlertTitle>{capitalize(type)}</AlertTitle>
            {message}
        </AlertMU>
    );
};

