import React from 'react';
import { LoginBox } from 'components/LoginBox';
import { useLoginMutation } from 'features/login/authApi';

export const Login: React.FC = () => {
    const [login] = useLoginMutation();

    const handleLogin = React.useCallback(async (username: string, password: string) => {
        try {
            await login({
                user: {
                    username,
                    password,
                }
            }).unwrap();
        } catch (err) {
            console.error(err);
        }
    }, [login]);

    return <LoginBox handleLogin={handleLogin} />;
};
