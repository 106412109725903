import { useAddQuestionnaireMutation, useGetQuestionnairesQuery, useUpdateQuestionnaireMutation, useDeleteQuestionnaireMutation } from 'features/questionnaire/questionnairesApi';
import { TableComponent } from 'components/Table';
import { Button, CircularProgress, Grid, IconButton, Paper, styled, TextField, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { ModalComponent } from 'components';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { dispatchAlert } from 'features/alerts/alertsSlice';

const CustomPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
}));

const modalIconStyle = {
    position: 'absolute' as 'absolute',
    top: '0',
    right: '0',
};

export const GeneralQuestionnaires: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>('');
    const [order, setOrder] = React.useState<number>(0);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = React.useState<number | null>(null);
    const [modalMode, setModalMode] = React.useState<string>('');

    const [addQuestionnaire, resultAddQuestionnaire] = useAddQuestionnaireMutation();
    const [updateQuestionnaire, resultUpdateQuestionnaire] = useUpdateQuestionnaireMutation();
    const [deleteQuestionnaire, resultDeleteQuestionnaire] = useDeleteQuestionnaireMutation();

    const dispatch = useAppDispatch();

    const { data, isLoading, error } = useGetQuestionnairesQuery();

    React.useEffect(() => {
        if (error) {
            const { code, message } = (error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [error]);

    React.useEffect(() => {
        if (resultAddQuestionnaire.error) {
            const { code, message } = (resultAddQuestionnaire.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultAddQuestionnaire.error]);

    React.useEffect(() => {
        if (resultUpdateQuestionnaire.error) {
            const { code, message } = (resultUpdateQuestionnaire.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultUpdateQuestionnaire.error]);

    React.useEffect(() => {
        if (resultDeleteQuestionnaire.error) {
            const { code, message } = (resultDeleteQuestionnaire.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultDeleteQuestionnaire.error]);

    const navigate = useNavigate();

    const handleOpenModal = React.useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleModalClose = React.useCallback(() => {
        setTitle('');
        setOrder(0);
        setSelectedQuestionnaire(null);
        setModalOpen(false);
    }, []);

    const handleChangeQuestionnaire = React.useCallback((event) => {
        setTitle(event.target.value);
    }, []);

    const handleChangeOrder = React.useCallback((event) => {
        setOrder(event.target.value);
    }, []);

    const handleClickAddQuestionnaire = React.useCallback(() => {
        handleOpenModal();
        setModalMode('add');
    }, []);

    const handleClickEditQuestionnaire = React.useCallback((questionnaire) => {
        setSelectedQuestionnaire(questionnaire.id);
        handleOpenModal();
        setModalMode('edit');
        setTitle(questionnaire.title);
    }, [data]);

    const handleModalConfirm = React.useCallback(() => {
        switch (modalMode) {
            case 'add':
                addQuestionnaire({
                  title,
                  order,
                });
                break;
            case 'edit':
                if (selectedQuestionnaire) {
                    updateQuestionnaire({
                      id: selectedQuestionnaire,
                      title,
                      order,
                    });
                }
                break;
            default:
        }
        handleModalClose();
    }, [title, order, selectedQuestionnaire]);

    const handleDeleteQuestionnaire = React.useCallback((questionnaire) => {
        deleteQuestionnaire({ id: questionnaire.id });
    }, []);

    const handleRowClick = React.useCallback((id: number) => {
        navigate(`/general/questionnaires/${id}/questions`);
    }, []);

    const tableColumns = React.useMemo(() => {
        return [
            {
                id: 'id',
                label: 'Questionnaire ID',
                minWidth: 70,
            },
            {
                id: 'title',
                label: 'Title',
                minWidth: 170,
            },
            {
                id: 'order',
                label: 'Order',
                minWidth: 170,
            },
            {
                id: 'createdAt',
                label: 'Created At',
                minWidth: 150,
                align: 'right',
            },
            {
                id: 'updatedAt',
                label: 'Updated At',
                minWidth: 150,
                align: 'right',
            },
            {
                id: 'actions',
                label: '',
                minWidth: 150,
                align: 'right',
            },
        ];
    }, []);

    const renderContent = React.useMemo(() => {
        if (isLoading) {
            return <CircularProgress />;
        }

        return (
            <TableComponent
                showPagination={true}
                columns={tableColumns}
                data={data || []}
                handleEdit={handleClickEditQuestionnaire}
                handleDelete={handleDeleteQuestionnaire}
                handleRowClick={handleRowClick}
            />
        );
    }, [data, isLoading, tableColumns]);

    const renderModalHead = React.useMemo(() => {
        return (
            <Grid container={true}>
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-around' }}>
                    <Typography
                        color="inherit"
                        variant="h6"
                        component="div"
                    >
                        Add Questionnaire
                        <IconButton style={modalIconStyle} onClick={handleModalClose}>
                            <CloseIcon>Close</CloseIcon>
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
        );
    }, []);

    const renderModalBody = React.useMemo(() => {
        return (
            <Grid container={true} direction="column">
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                    <TextField
                        label="Title"
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                        value={title}
                        onChange={handleChangeQuestionnaire}
                    />
                </Grid>
                <Grid container={true} direction="column">
                    <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                        <TextField
                            label="Order"
                            variant="outlined"
                            fullWidth={true}
                            size="small"
                            value={order}
                            onChange={handleChangeOrder}
                        />
                    </Grid>
                </Grid>
                <Grid container={true} spacing={4} justifyContent="center" alignItems="center">
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="error"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalConfirm}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [title, order]);

    const renderModal = React.useMemo(() => {
        return (
            <ModalComponent
                open={modalOpen}
                handleClose={handleModalClose}
                modalHead={renderModalHead}
                modalBody={renderModalBody}
            />
        );
    }, [modalOpen, renderModalHead, renderModalBody]);

    const renderTitle = React.useMemo(() => {
        return (
            <CustomToolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    Questionnaires
                </Typography>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    align="right"
                >
                    <IconButton onClick={handleClickAddQuestionnaire}>
                        <AddIcon />
                    </IconButton>
                    Add General Questionnaire
                </Typography>
            </CustomToolbar>
        );
    }, []);


    return (
        <CustomPaper elevation={4}>
            {renderTitle}
            {renderContent}
            {renderModal}
        </CustomPaper>
    );
};
