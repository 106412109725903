import { useDeleteVideoMutation, useGetVideosQuery, useUpdateVideoMutation } from 'features/videos/videosApi';
import { TableComponent } from 'components/Table';
import { Button, CircularProgress, FormControl, Paper, Grid, IconButton, Input, styled, TextField, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { ModalComponent } from 'components';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dispatchAlert } from 'features/alerts/alertsSlice';
import FormData from 'form-data';
import { addVideo } from 'features/videos/videosSlice';

const CustomPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
}));

const modalIconStyle = {
    position: 'absolute' as 'absolute',
    top: '0',
    right: '0',
};

export const GeneralVideos: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [order, setOrder] = React.useState<number>(0);
    const [selectedVideo, setSelectedVideo] = React.useState<number | null>(null);
    const [modalMode, setModalMode] = React.useState<string>('');
    const [videoFile, setVideoFile] = React.useState<File | null>(null);
    const shouldRefetch = useAppSelector(state => state.videos.shouldRefetch);

    const [updateVideo, resultUpdateVideo] = useUpdateVideoMutation();
    const [deleteVideo, resultDeleteVideo] = useDeleteVideoMutation();

    const dispatch = useAppDispatch();

    const { data, isLoading, error, refetch } = useGetVideosQuery();

    React.useEffect(() => {
        refetch();
    }, [shouldRefetch]);

    React.useEffect(() => {
        if (error) {
            const { code, message } = (error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [error]);

    React.useEffect(() => {
        if (resultUpdateVideo.error) {
            const { code, message } = (resultUpdateVideo.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultUpdateVideo.error]);

    React.useEffect(() => {
        if (resultDeleteVideo.error) {
            const { code, message } = (resultDeleteVideo.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultDeleteVideo.error]);

    const onFileChange = React.useCallback((event: any) => {
        setVideoFile(event.target.files[0]);
    }, []);

    const handleOpenModal = React.useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleModalClose = React.useCallback(() => {
        setOrder(0);
        setSelectedVideo(null);
        setModalOpen(false);
        setVideoFile(null);
    }, []);

    const handleChangeOrder = React.useCallback((event) => {
        setOrder(event.target.value);
    }, []);

    const handleClickAddVideo = React.useCallback(() => {
        handleOpenModal();
        setModalMode('add');
    }, []);

    const handleClickEditVideo = React.useCallback((video) => {
        setSelectedVideo(video.id);
        handleOpenModal();
        setModalMode('edit');
    }, [handleOpenModal]);

    const handleModalConfirm = React.useCallback(async () => {
        switch (modalMode) {
            case 'add':
                if (videoFile) {
                    const data = new FormData();

                    data.append('order', String(order));
                    data.append('file', videoFile);

                    dispatch(addVideo(data));
                }
                break;
            case 'edit':
                if (selectedVideo) {
                    updateVideo({ id: selectedVideo, order });
                }
                break;
            default:
        }
        handleModalClose();
    }, [order, selectedVideo, videoFile, addVideo, updateVideo, onFileChange]);

    const handleDeleteVideo = React.useCallback((video) => {
        deleteVideo({ id: video.id });
    }, [deleteVideo]);

    const tableColumns = React.useMemo(() => {
        return [
            {
                id: 'id',
                label: 'Video ID',
                minWidth: 50,
            },
            {
                id: 'videoSource',
                label: 'Video Source',
                minWidth: 200,
            },
            {
                id: 'order',
                label: 'Order',
                minWidth: 50,
            },
            {
                id: 'createdAt',
                label: 'Created At',
                minWidth: 150,
                align: 'right',
            },
            {
                id: 'updatedAt',
                label: 'Updated At',
                minWidth: 150,
                align: 'right',
            },
            {
                id: 'actions',
                label: '',
                minWidth: 150,
                align: 'right',
            }
        ];
    }, []);

    const renderTitle = React.useMemo(() => {
        return (
            <CustomToolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    Videos
                </Typography>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    align="right"
                >
                    <IconButton onClick={handleClickAddVideo}>
                        <AddIcon />
                    </IconButton>
                    Add Video
                </Typography>
            </CustomToolbar>
        );
    }, [handleClickAddVideo]);

    const renderContent = React.useMemo(() => {
        if (isLoading) {
            return <CircularProgress />;
        }

        return (
            <TableComponent
                showPagination={true}
                columns={tableColumns}
                data={data || []}
                handleEdit={handleClickEditVideo}
                handleDelete={handleDeleteVideo}
            />
        );
    }, [data, isLoading, tableColumns, handleClickEditVideo, handleDeleteVideo]);

    const renderModalHead = React.useMemo(() => {
        return (
            <Grid container={true}>
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-around' }}>
                    <Typography
                        color="inherit"
                        variant="h6"
                        component="div"
                    >
                        {modalMode === 'add' ? 'Add' : 'Edit'} Video
                        <IconButton style={modalIconStyle} onClick={handleModalClose}>
                            <CloseIcon>Close</CloseIcon>
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
        );
    }, [modalMode, handleModalClose]);

    const renderModalBody = React.useMemo(() => {
        return (
            <Grid container={true} direction="column">
                <Grid container={true} direction="column">
                    <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                        <TextField
                            label="Order"
                            variant="outlined"
                            fullWidth={true}
                            size="small"
                            value={order}
                            onChange={handleChangeOrder}
                        />
                    </Grid>
                </Grid>
                {modalMode === 'add' && (
                  <Grid container={true} direction="column">
                      <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                          <FormControl>
                              <Input
                                  id="file-input"
                                  type="file"
                                  onChange={onFileChange}
                              />
                          </FormControl>
                      </Grid>
                  </Grid>
                )}
                <Grid container={true} spacing={4} justifyContent="center" alignItems="center">
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="error"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalConfirm}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [order, handleModalConfirm, handleModalClose, modalMode]);

    const renderModal = React.useMemo(() => {
        return (
            <ModalComponent
                open={modalOpen}
                handleClose={handleModalClose}
                modalHead={renderModalHead}
                modalBody={renderModalBody}
            />
        );
    }, [modalOpen, renderModalHead, renderModalBody, handleModalClose]);

    return (
        <CustomPaper elevation={4}>
            {renderTitle}
            {renderContent}
            {renderModal}
        </CustomPaper>
    );
};
