import { api } from 'app/api';
import { Question } from 'app/types';

export interface AddQuestion {
  question: string;
  order: number;
  multipleAnswers: boolean;
  questionnaireId: number;
}

export interface UpdateQuestion {
  id: number;
  question?: string;
  order?: number;
  multipleAnswers?: boolean;
}

export const questionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getQuestions: builder.query<Question[], void>({
      query: () => ({
        url: 'admin/questions',
        method: 'GET',
      }),
      providesTags: ['Question'],
    }),
    getQuestion: builder.query<Question, { id: string }>({
      query: (params) => ({
        url: `admin/questions/${params.id}`,
        method: 'GET',
      }),
      providesTags: ['Question'],
    }),
    addQuestion: builder.mutation<Question, AddQuestion>({
      query: (params) => ({
        url: 'admin/questions',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Questionnaire', 'Question'],
    }),
    updateQuestion: builder.mutation<Question, UpdateQuestion>({
      query: (params) => ({
        url: `admin/questions/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Questionnaire', 'Question'],
    }),
    deleteQuestion: builder.mutation<Question, { id: number }>({
      query: (params) => ({
        url: `admin/questions/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Questionnaire', 'Question'],
    }),
  }),
})

export const {
  useGetQuestionsQuery,
  useGetQuestionQuery,
  useAddQuestionMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
} = questionsApi;
