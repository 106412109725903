import { useAddQuestionMutation, useUpdateQuestionMutation, useDeleteQuestionMutation } from 'features/questions/questionsApi';
import { TableComponent } from 'components/Table';
import { Box, Button, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, Paper, styled, Switch, TextField, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { CardComponent, ModalComponent } from 'components';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useGetQuestionnaireQuery } from 'features/questionnaire/questionnairesApi';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { dispatchAlert } from 'features/alerts/alertsSlice';

const CustomPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
}));

const CustomBox = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

const modalIconStyle = {
    position: 'absolute' as 'absolute',
    top: '0',
    right: '0',
};

export const GeneralQuestions: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [question, setQuestion] = React.useState<string>('');
    const [order, setOrder] = React.useState<number>(0);
    const [selectedQuestion, setSelectedQuestion] = React.useState<number | null>(null);
    const [modalMode, setModalMode] = React.useState<string>('');
    const [multipleAnswers, setMultipleAnswers] = React.useState<boolean>(false);

    const [addQuestion, resultAddQuestion] = useAddQuestionMutation();
    const [updateQuestion, resultUpdateQuestion] = useUpdateQuestionMutation();
    const [deleteQuestion, resultDeleteQuestion] = useDeleteQuestionMutation();

    const dispatch = useAppDispatch();

    const { questionnaireId } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, error } = useGetQuestionnaireQuery({ id: questionnaireId || '' });

    React.useEffect(() => {
        if (error) {
            const { code, message } = (error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [error]);

    React.useEffect(() => {
        if (resultAddQuestion.error) {
            const { code, message } = (resultAddQuestion.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultAddQuestion.error]);

    React.useEffect(() => {
        if (resultUpdateQuestion.error) {
            const { code, message } = (resultUpdateQuestion.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultUpdateQuestion.error]);

    React.useEffect(() => {
        if (resultDeleteQuestion.error) {
            const { code, message } = (resultDeleteQuestion.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultDeleteQuestion.error]);

    const handleOpenModal = React.useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleModalClose = React.useCallback(() => {
        setQuestion('');
        setOrder(0);
        setSelectedQuestion(null);
        setModalOpen(false);
        setMultipleAnswers(false);
    }, []);

    const handleChangeQuestion = React.useCallback((event) => {
        setQuestion(event.target.value);
    }, []);

    const handleChangeOrder = React.useCallback((event) => {
        setOrder(event.target.value);
    }, []);

    const handleClickAddQuestion = React.useCallback(() => {
        handleOpenModal();
        setModalMode('add');
    }, []);

    const handleClickEditQuestion = React.useCallback((question) => {
        setSelectedQuestion(question.id);
        handleOpenModal();
        setModalMode('edit');
        setQuestion(question.question);
        setMultipleAnswers(question.multipleAnswers);
    }, []);

    const handleModalConfirm = React.useCallback(() => {
        if (questionnaireId) {
            switch (modalMode) {
                case 'add':
                    addQuestion({
                        question,
                        order,
                        multipleAnswers,
                        questionnaireId: +questionnaireId,
                    });
                    break;
                case 'edit':
                    if (selectedQuestion) {
                        updateQuestion({
                            id: selectedQuestion,
                            question,
                            order,
                            multipleAnswers,
                        });
                    }
                    break;
                default:
            }
        }

        handleModalClose();
    }, [question, order, selectedQuestion, multipleAnswers, questionnaireId]);

    const handleDeleteQuestion = React.useCallback((question) => {
        deleteQuestion({ id: question.id });
    }, []);

    const handleToggleSwitcher = React.useCallback((event) => {
        setMultipleAnswers(event.target.value === 'false' ? true : false);
    }, []);

    const handleRowClick = React.useCallback((id: number) => {
        navigate(`/general/questionnaires/${questionnaireId}/questions/${id}/answers`);
    }, [questionnaireId]);

    const tableColumns = React.useMemo(() => {
        return [
            {
                id: 'id',
                label: 'Question ID',
                minWidth: 70,
            },
            {
                id: 'question',
                label: 'Question',
                minWidth: 170,
            },
            {
                id: 'order',
                label: 'Order',
                minWidth: 170,
            },
            {
                id: 'multipleAnswers',
                label: 'Multiple Answers',
                minWidth: 130,
            },
            {
                id: 'createdAt',
                label: 'Created At',
                minWidth: 150,
                align: 'right',
            },
            {
                id: 'updatedAt',
                label: 'Updated At',
                minWidth: 150,
                align: 'right',
            },
            {
                id: 'actions',
                label: '',
                minWidth: 150,
                align: 'right',
            },
        ];
    }, []);

    const renderContent = React.useMemo(() => {
        if (isLoading) {
            return <CircularProgress />;
        }

        return (
            <TableComponent
                showPagination={true}
                columns={tableColumns}
                data={data?.questions || []}
                handleEdit={handleClickEditQuestion}
                handleDelete={handleDeleteQuestion}
                handleRowClick={handleRowClick}
            />
        );
    }, [data?.questions, isLoading, tableColumns]);

    const renderModalHead = React.useMemo(() => {
        return (
            <Grid container={true}>
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-around' }}>
                    <Typography
                        color="inherit"
                        variant="h6"
                        component="div"
                    >
                        Add Question
                        <IconButton style={modalIconStyle} onClick={handleModalClose}>
                            <CloseIcon>Close</CloseIcon>
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
        );
    }, []);

    const renderModalBody = React.useMemo(() => {
        return (
            <Grid container={true} direction="column">
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                    <TextField
                        label="Question"
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                        value={question}
                        onChange={handleChangeQuestion}
                    />
                </Grid>
                <Grid container={true} direction="column">
                    <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                        <TextField
                            label="Order"
                            variant="outlined"
                            fullWidth={true}
                            size="small"
                            value={order}
                            onChange={handleChangeOrder}
                        />
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    value={multipleAnswers ? 'true' : 'false'}
                                    checked={multipleAnswers}
                                    onChange={handleToggleSwitcher}
                                />
                            }
                            label="Multiple Answers"
                        />
                    </FormGroup>
                </Grid>
                <Grid container={true} spacing={4} justifyContent="center" alignItems="center">
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="error"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalConfirm}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [question, order, multipleAnswers]);

    const renderModal = React.useMemo(() => {
        return (
            <ModalComponent
                open={modalOpen}
                handleClose={handleModalClose}
                modalHead={renderModalHead}
                modalBody={renderModalBody}
            />
        );
    }, [modalOpen, renderModalHead, renderModalBody]);

    const renderTitle = React.useMemo(() => {
        return (
            <CustomToolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    Questions
                </Typography>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    align="right"
                >
                    <IconButton onClick={handleClickAddQuestion}>
                        <AddIcon />
                    </IconButton>
                    Add Question
                </Typography>
            </CustomToolbar>
        );
    }, []);

    const renderQuestionnaireInfo = React.useMemo(() => {
        if (isLoading) {
            return <CircularProgress />;
        }

        return (
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <CardComponent
                        id="id"
                        label="Questionnaire ID"
                        value={data?.id || ''}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <CardComponent
                        id="title"
                        label="Title"
                        value={data?.title || ''}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <CardComponent
                        id="createdAt"
                        label="Created At"
                        value={data?.createdAt || ''}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={4}>
                    <CardComponent
                        id="updatedAt"
                        label="Updated At"
                        value={data?.updatedAt || ''}
                    />
                </Grid>
                {data?.user && (
                    <React.Fragment>
                        <Grid item={true} xs={12} sm={6} md={4}>
                            <CardComponent
                                id="userId"
                                label="Questionnaire User ID"
                                value={data?.user.id || ''}
                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={4}>
                            <CardComponent
                                id="userId"
                                label="Questionnaire User ID"
                                value={data?.user.username || ''}
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }, [data]);

    return (
        <React.Fragment>
            <CustomBox>
                {renderQuestionnaireInfo}
            </CustomBox>
            <CustomPaper elevation={4}>
                {renderTitle}
                {renderContent}
                {renderModal}
            </CustomPaper>
        </React.Fragment>
    );
};
