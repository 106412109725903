import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { deleteAlert, deleteAlertByIndex } from 'features/alerts/alertsSlice';
import { Alert } from 'components';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'absolute',
            right: 0,
            zIndex: 50,
        },
        alert: {
            marginTop: 10,
            marginRight: 10,
            width: 400,
        },
    }),
);

export const Alerts: React.FC = () => {
    const classes = useStyles();

    const dispatch = useAppDispatch();
    const alerts = useAppSelector(state => state.alerts.alerts);

    const handleCloseAlert = React.useCallback(() => {
        dispatch(deleteAlert());
    }, []);

    const handleCloseAlertByClick = React.useCallback((alertIndex: number) => {
        dispatch(deleteAlertByIndex(alertIndex));
    }, []);

    const renderAlerts = React.useMemo(() => {
        return alerts.map((alert: any, index: number) => {
            const alertParams = {
                ...alert,
                onClose: handleCloseAlert,
                onCloseByClick: () => handleCloseAlertByClick(index),
            };

            return (
                <div key={index} className={classes.alert} onClick={() => handleCloseAlertByClick(index)}>
                    <Alert
                        {...alertParams}
                    />
                </div>
            );
        });
    }, [alerts]);

    return (
        <div className={classes.root}>
            {renderAlerts}
        </div>
    );
};
