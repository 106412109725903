import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { localeDate } from 'helpers';

interface CardProps {
  id: string;
  label: string;
  value?: string | number;
  onClick?: () => void;
}

export const CardComponent: React.FC<CardProps> = ({
  label,
  value,
  id,
  onClick,
}: CardProps) => {
  const renderValue = React.useMemo(() => {
    if (id === 'createdAt') {
      return localeDate(value, 'fullDate');
    }

    return value;
  }, [value]);

  return (
    <Box sx={{ minWidth: 200 }} onClick={onClick}>
      <Card variant="outlined">
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {label}
          </Typography>
          <Typography variant="h5" component="div">
            {renderValue}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
