import { api } from 'app/api';
import { User } from 'app/types';
import { buildQueryString } from 'helpers';

export interface AddTablet {
  user: {
    username: string;
    password: string;
    role: string;
    displayQuestionnaire: boolean;
  };
}

export interface GetTablets {
  field?: string;
  side?: string;
}

export interface UpdateTablet {
  id: number;
  username?: string;
  password?: string;
  role?: string;
  displayQuestionnaire?: boolean;
}

export const tabletsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTablets: builder.query<User[], GetTablets>({
      query: (params) => ({
        url: `admin/accounts?${buildQueryString(params)}`,
        method: 'GET',
        keepUnusedDataFor: 0,
      }),
      providesTags: ['Tablet'],
    }),
    getTablet: builder.query<User, number>({
      query: (id) => ({
        url: `admin/accounts/${id}`,
        method: 'GET',
        refetchOnMountOrArgChange: true,
      }),
      providesTags: ['Tablet'],
    }),
    addTablet: builder.mutation<User, AddTablet>({
      query: (params) => ({
        url: 'admin/accounts/new',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Tablet'],
    }),
    updateTablet: builder.mutation<User, UpdateTablet>({
      query: (params) => ({
        url: `admin/accounts/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Tablet'],
    }),
    deleteTablet: builder.mutation<User, { id: number }>({
      query: (params) => ({
        url: `admin/accounts/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tablet'],
    }),
  }),
})

export const {
  useGetTabletsQuery,
  useGetTabletQuery,
  useAddTabletMutation,
  useUpdateTabletMutation,
  useDeleteTabletMutation,
} = tabletsApi;
