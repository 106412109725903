import { createSlice } from '@reduxjs/toolkit';
import { accountsApi } from 'features/login/accountsApi';
import { authApi } from 'features/login/authApi';
import { User } from 'app/types';

interface LoginState {
  user: User | null;
  isLoading: boolean;
  error: any;
  shouldRefetch: boolean;
}

const initialState: LoginState = {
  user: null,
  isLoading: false,
  error: null,
  shouldRefetch: false,
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearUserData(state) {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          const { user } = payload;

          if (user.token) {
            localStorage.setItem('token', user.token);
            delete user.token;
          }

          state.user = payload.user
        }
      )
      .addMatcher(
        accountsApi.endpoints.getUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload.user;
        }
      )
  },
});

export const { clearUserData } = loginSlice.actions;
export default loginSlice.reducer;
