import React from 'react';
import { useGetConfigsQuery, useAddConfigMutation, useUpdateConfigMutation, useDeleteConfigMutation } from 'features/configurations/configurationsApi';
import { TableComponent } from 'components/Table';
import { Button, CircularProgress, Grid, IconButton, Paper, styled, TextField, Toolbar, Typography } from '@mui/material';
import { ModalComponent } from 'components';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { dispatchAlert } from 'features/alerts/alertsSlice';

const CustomPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
}));

const modalIconStyle = {
    position: 'absolute' as 'absolute',
    top: '0',
    right: '0',
};

export const GeneralConfigurations: React.FC = () => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [key, setKey] = React.useState<string>('');
  const [value, setValue] = React.useState<string>('');
  const [selectedConfig, setSelectedConfig] = React.useState<number | null>(null);
  const [modalMode, setModalMode] = React.useState<string>('');

  const [addConfig, resultAddConfig] = useAddConfigMutation();
  const [updateConfig, resultUpdateConfig] = useUpdateConfigMutation();
  const [deleteConfig, resultDeleteConfig] = useDeleteConfigMutation();

  const dispatch = useAppDispatch();

  const { data, isLoading, error } = useGetConfigsQuery();

  React.useEffect(() => {
      if (error) {
          const { code, message } = (error as any).data;

          dispatch(
              dispatchAlert({
                  type: 'error',
                  code,
                  message,
              },
          ));
      }
  }, [error]);

  React.useEffect(() => {
      if (resultAddConfig.error) {
          const { code, message } = (resultAddConfig.error as any).data;

          dispatch(
              dispatchAlert({
                  type: 'error',
                  code,
                  message,
              },
          ));
      }
  }, [resultAddConfig.error]);

  React.useEffect(() => {
      if (resultUpdateConfig.error) {
          const { code, message } = (resultUpdateConfig.error as any).data;

          dispatch(
              dispatchAlert({
                  type: 'error',
                  code,
                  message,
              },
          ));
      }
  }, [resultUpdateConfig.error]);

  React.useEffect(() => {
      if (resultDeleteConfig.error) {
          const { code, message } = (resultDeleteConfig.error as any).data;

          dispatch(
              dispatchAlert({
                  type: 'error',
                  code,
                  message,
              },
          ));
      }
  }, [resultDeleteConfig.error]);

  const navigate = useNavigate();

  const handleOpenModal = React.useCallback(() => {
      setModalOpen(true);
  }, []);

  const handleModalClose = React.useCallback(() => {
      setKey('');
      setValue('');
      setSelectedConfig(null);
      setModalOpen(false);
  }, []);

  const handleChangeKey = React.useCallback((event) => {
      setKey(event.target.value);
  }, []);

  const handleChangeValue = React.useCallback((event) => {
      setValue(event.target.value);
  }, []);

  const handleClickAddConfig = React.useCallback(() => {
      handleOpenModal();
      setModalMode('add');
  }, []);

  const handleClickEditConfig = React.useCallback((config) => {
      setSelectedConfig(config.id);
      handleOpenModal();
      setModalMode('edit');
      setKey(config.key);
      setValue(config.value);
  }, [data]);

  const handleModalConfirm = React.useCallback(() => {
      switch (modalMode) {
          case 'add':
              addConfig({
                key,
                value,
              });
              break;
          case 'edit':
              if (selectedConfig) {
                  updateConfig({
                    id: selectedConfig,
                    key,
                    value,
                  });
              }
              break;
          default:
      }
      handleModalClose();
  }, [key, value, selectedConfig]);

  const handleDeleteConfig = React.useCallback((config) => {
      deleteConfig({ id: config.id });
  }, []);

  const tableColumns = React.useMemo(() => {
      return [
          {
              id: 'key',
              label: 'Key',
              minWidth: 170,
          },
          {
              id: 'value',
              label: 'Value',
              minWidth: 170,
          },
          {
              id: 'actions',
              label: '',
              minWidth: 150,
              align: 'right',
          },
      ];
  }, []);

  const renderContent = React.useMemo(() => {
      if (isLoading) {
          return <CircularProgress />;
      }

      return (
          <TableComponent
              showPagination={true}
              columns={tableColumns}
              data={data || []}
              handleEdit={handleClickEditConfig}
              handleDelete={handleDeleteConfig}
          />
      );
  }, [data, isLoading, tableColumns]);

  const renderModalHead = React.useMemo(() => {
      return (
          <Grid container={true}>
              <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-around' }}>
                  <Typography
                      color="inherit"
                      variant="h6"
                      component="div"
                  >
                      Add Config
                      <IconButton style={modalIconStyle} onClick={handleModalClose}>
                          <CloseIcon>Close</CloseIcon>
                      </IconButton>
                  </Typography>
              </Grid>
          </Grid>
      );
  }, []);

  const renderModalBody = React.useMemo(() => {
      return (
          <Grid container={true} direction="column">
              <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                  <TextField
                      label="Key"
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                      value={key}
                      onChange={handleChangeKey}
                  />
              </Grid>
              <Grid container={true} direction="column">
                  <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                      <TextField
                          label="Value"
                          variant="outlined"
                          fullWidth={true}
                          size="small"
                          value={value}
                          onChange={handleChangeValue}
                      />
                  </Grid>
              </Grid>
              <Grid container={true} spacing={4} justifyContent="center" alignItems="center">
                  <Grid item={true} xs={12} sm={6}>
                      <Button
                          variant="outlined"
                          color="error"
                          fullWidth={true}
                          disableRipple={true}
                          onClick={handleModalClose}
                      >
                          Cancel
                      </Button>
                  </Grid>
                  <Grid item={true} xs={12} sm={6}>
                      <Button
                          variant="outlined"
                          fullWidth={true}
                          disableRipple={true}
                          onClick={handleModalConfirm}
                      >
                          Confirm
                      </Button>
                  </Grid>
              </Grid>
          </Grid>
      );
  }, [key, value]);

  const renderModal = React.useMemo(() => {
      return (
          <ModalComponent
              open={modalOpen}
              handleClose={handleModalClose}
              modalHead={renderModalHead}
              modalBody={renderModalBody}
          />
      );
  }, [modalOpen, renderModalHead, renderModalBody]);

  const renderTitle = React.useMemo(() => {
      return (
          <CustomToolbar
              sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
              }}
          >
              <Typography
                  sx={{ flex: '1 1 100%' }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
              >
                  Configurations
              </Typography>
              <Typography
                  sx={{ flex: '1 1 100%' }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                  align="right"
              >
                  <IconButton onClick={handleClickAddConfig}>
                      <AddIcon />
                  </IconButton>
                  Add General Configurations
              </Typography>
          </CustomToolbar>
      );
  }, []);

  return (
      <CustomPaper elevation={4}>
          {renderTitle}
          {renderContent}
          {renderModal}
      </CustomPaper>
  );
};
