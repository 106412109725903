import moment from 'moment-timezone';

let timezone = '';
export const getTimezone = () => timezone.length > 0 ? timezone : moment.tz.guess();
export const setTimezone = (tz: string) => timezone = tz;

export const stdTimezoneOffset = (date: Date) => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);

  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

const getFormat = (type: string): string | undefined => {
  switch (type) {
    case 'fullDate':
      return 'DD-MM-YYYY HH:mm:ss';
    case 'shortDate':
      return 'DD-MM-YYYY HH:mm';
    case 'time':
      return 'HH:mm:ss';
    case 'date':
      return 'DD-MM-YYYY';
    case 'shortYear':
      return 'DD-MM-YY';
    default:
      return '';
  }
}

export const localeDate = (date: any, format: string, timezone = getTimezone()) => {
  const formatDisplay = getFormat(format);
  const isUnix = typeof date === 'number';

  const momentObj = isUnix ? moment.unix(date) : moment(date);

  return momentObj.tz(timezone).format(formatDisplay);
};
