import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from 'components';

import { Login } from 'containers/Login';
import {
    GeneralVideos,
    GeneralQuestionnaires,
    GeneralQuestions,
    GeneralAnswers,
    GeneralConfigurations,
    GeneralImages,
} from 'containers/General';
import {
    Tablets,
    Tablet,
} from 'containers/Tablets';
import { useGetUserQuery } from 'features/login/accountsApi';
import { useAppSelector } from 'app/hooks';
import { CircularProgress } from '@mui/material';

const PrivateRoute = ({ children, userIsLoggedIn }: any) => {
    return userIsLoggedIn ? children : <Navigate to='/' />;
};

const PublicRoute = ({ children, userIsLoggedIn }: any) => {
    return userIsLoggedIn ? <Navigate to='/general' /> : children;
};

export const Navigation: React.FC = () => {
    const { data, isLoading } = useGetUserQuery();
    const user = useAppSelector(state => state.auth.user);

    const userIsLoggedIn = React.useMemo(() => {
        const token = localStorage.getItem('token');

        return token && (data || user) ? true : false;
    }, [data, user]);

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route
                    path='/'
                    element={
                        <PublicRoute userIsLoggedIn={userIsLoggedIn}>
                            <Login />
                        </PublicRoute>
                    }
                />
                <Route
                    path='/general'
                    element={
                        <PrivateRoute userIsLoggedIn={userIsLoggedIn}>
                            <Navigate to='/general/videos' />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/general/videos'
                    element={
                        <PrivateRoute userIsLoggedIn={userIsLoggedIn}>
                            <GeneralVideos />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/general/questionnaires'
                    element={
                        <PrivateRoute userIsLoggedIn={userIsLoggedIn}>
                            <GeneralQuestionnaires />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/general/configurations'
                    element={
                        <PrivateRoute userIsLoggedIn={userIsLoggedIn}>
                            <GeneralConfigurations />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/general/images'
                    element={
                        <PrivateRoute userIsLoggedIn={userIsLoggedIn}>
                            <GeneralImages />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/general/questionnaires/:questionnaireId/questions'
                    element={
                        <PrivateRoute userIsLoggedIn={userIsLoggedIn}>
                            <GeneralQuestions />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/general/questionnaires/:questionnaireId/questions/:questionId/answers'
                    element={
                        <PrivateRoute userIsLoggedIn={userIsLoggedIn}>
                            <GeneralAnswers />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/tablets'
                    element={
                        <PrivateRoute userIsLoggedIn={userIsLoggedIn}>
                            <Tablets />
                        </PrivateRoute>
                    }
                />
                <Route
                    path='/tablets/:tabletID'
                    element={
                        <PrivateRoute userIsLoggedIn={userIsLoggedIn}>
                            <Tablet />
                        </PrivateRoute>
                    }
                />
                <Route path='*' element={<Navigate to='/' />} />
            </Route>
        </Routes>
    );
};
