import { Image } from 'app/types';
import { api } from 'app/api';

export interface AddImage {
  userId?: number;
  file?: FormData;
}

export const imagesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getImages: builder.query<Image[], void>({
      query: () => ({
        url: 'admin/images',
        method: 'GET',
      }),
      providesTags: ['Image'],
    }),
    deleteImage: builder.mutation<Image, { id: number }>({
      query: (params) => ({
        url: `admin/images/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tablet', 'Image'],
    }),
  }),
})

export const {
  useGetImagesQuery,
  useDeleteImageMutation,
} = imagesApi;
