import { Video } from 'app/types';
import { api } from 'app/api';

export interface AddVideo {
  order?: number;
  userId?: number;
  file?: FormData;
}

export interface UpdateVideo {
  id: number;
  order: number;
  userId?: number;
}

export const videosApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getVideos: builder.query<Video[], void>({
      query: () => ({
        url: 'admin/videos',
        method: 'GET',
      }),
      providesTags: ['Video'],
    }),
    updateVideo: builder.mutation<Video, UpdateVideo>({
      query: (params) => ({
        url: `admin/videos/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Tablet', 'Video'],
    }),
    deleteVideo: builder.mutation<Video, { id: number }>({
      query: (params) => ({
        url: `admin/videos/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tablet', 'Video'],
    }),
  }),
})

export const {
  useGetVideosQuery,
  useUpdateVideoMutation,
  useDeleteVideoMutation,
} = videosApi;
