import { api } from 'app/api';
import { Config } from 'app/types';

export interface AddConfig {
  key: string;
  value: string;
  userId?: number;
}

export interface UpdateConfig {
  id: number;
  key: string;
  value: string;
  userId?: number;
}

export const configurationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getConfigs: builder.query<Config[], void>({
      query: () => ({
        url: 'admin/configs',
        method: 'GET',
      }),
      providesTags: ['Config'],
    }),
    addConfig: builder.mutation<Config, AddConfig>({
      query: (params) => ({
        url: 'admin/configs',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Tablet', 'Config'],
    }),
    updateConfig: builder.mutation<Config, UpdateConfig>({
      query: (params) => ({
        url: `admin/configs/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Tablet', 'Config'],
    }),
    deleteConfig: builder.mutation<Config, { id: number }>({
      query: (params) => ({
        url: `admin/configs/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tablet', 'Config'],
    }),
  }),
})

export const {
  useGetConfigsQuery,
  useAddConfigMutation,
  useUpdateConfigMutation,
  useDeleteConfigMutation,
} = configurationsApi;
