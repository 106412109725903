import { useGetTabletsQuery, useAddTabletMutation, useUpdateTabletMutation, useDeleteTabletMutation  } from 'features/tablets/tabletsApi';
import { TableComponent } from 'components/Table';
import { Button, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, Paper, styled, Switch, TextField, Toolbar, Typography } from '@mui/material';
import { ModalComponent } from 'components';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { dispatchAlert } from 'features/alerts/alertsSlice';

const CustomPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
}));

const modalIconStyle = {
    position: 'absolute' as 'absolute',
    top: '0',
    right: '0',
};

type Order = 'asc' | 'desc';

export const Tablets: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [username, setUsername] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [role, setRole] = React.useState<string>('');
    const [selectedUser, setSelectedUser] = React.useState<number | null>(null);
    const [modalMode, setModalMode] = React.useState<string>('');
    const [orderSide, setOrderSide] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<string>('');
    const [displayQuestionnaire, setDisplayQuestionnaire] = React.useState<boolean>(true);

    const [addUser, resultAddUser] = useAddTabletMutation();
    const [updateUser, resultUpdateUser] = useUpdateTabletMutation();
    const [deleteUser, resultDeleteUser] = useDeleteTabletMutation();

    const dispatch = useAppDispatch();

    const params = React.useMemo(() => {
        if (!orderBy || !orderSide) {
            return {};
        }

        return {
            field: orderBy,
            side: orderSide,
        }
    }, [orderBy, orderSide]);
    const { data, isLoading, error } = useGetTabletsQuery(params);

    React.useEffect(() => {
        if (error) {
            const { code, message } = (error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [error]);

    React.useEffect(() => {
        if (resultAddUser.error) {
            const { code, message } = (resultAddUser.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultAddUser.error]);

    React.useEffect(() => {
        if (resultUpdateUser.error) {
            const { code, message } = (resultUpdateUser.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultUpdateUser.error]);

    React.useEffect(() => {
        if (resultDeleteUser.error) {
            const { code, message } = (resultDeleteUser.error as any).data;

            dispatch(
                dispatchAlert({
                    type: 'error',
                    code,
                    message,
                },
            ));
        }
    }, [resultDeleteUser.error]);

    const navigate = useNavigate();

    const handleOpenModal = React.useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleModalClose = React.useCallback(() => {
        setUsername('');
        setPassword('');
        setSelectedUser(null);
        setModalOpen(false);
        setDisplayQuestionnaire(false);
    }, []);

    const handleChangeUsername = React.useCallback((event) => {
        setUsername(event.target.value);
    }, [username]);

    const handleChangePassword = React.useCallback((event) => {
        setPassword(event.target.value);
    }, [password]);

    const handleChangeRole = React.useCallback((event) => {
        setRole(event.target.value);
    }, [role]);

    const handleClickAddUser = React.useCallback(() => {
        handleOpenModal();
        setModalMode('add');
    }, []);

    const handleClickEditUser = React.useCallback((user) => {
        setSelectedUser(user.id);
        handleOpenModal();
        setModalMode('edit');
        setUsername(user.username);
        setRole(user.role);
        setDisplayQuestionnaire(user.displayQuestionnaire);
    }, []);

    const handleModalConfirm = React.useCallback(() => {
        switch (modalMode) {
            case 'add':
                addUser({
                    user: {
                        username,
                        password,
                        role,
                        displayQuestionnaire,
                    },
                });
                break;
            case 'edit':
                if (selectedUser) {
                    updateUser({
                      id: selectedUser,
                      username,
                      password,
                      role,
                      displayQuestionnaire,
                    });
                }
                break;
            default:
        }
        handleModalClose();
    }, [username, password, selectedUser, role, displayQuestionnaire]);

    const handleDeleteUser = React.useCallback((user) => {
        deleteUser({ id: user.id });
    }, []);

    const handleToggleSwitcher = React.useCallback((event) => {
        setDisplayQuestionnaire(event.target.value === 'false' ? true : false);
    }, []);

    const handleRowClick = React.useCallback((id: number) => {
        navigate(`/tablets/${id}`);
    }, []);

    const tableColumns = React.useMemo(() => {
        return [
            {
                id: 'id',
                label: 'Tablet ID',
                minWidth: 70,
                sortable: true,
            },
            {
                id: 'username',
                label: 'Username',
                minWidth: 170,
                sortable: true,
            },
            {
                id: 'role',
                label: 'Role',
                minWidth: 150,
                align: 'right',
                sortable: true,
            },
            {
                id: 'lastActionTime',
                label: 'Last Action Time',
                minWidth: 150,
                align: 'right',
                sortable: true,
            },
            {
                id: 'createdAt',
                label: 'Created At',
                minWidth: 150,
                align: 'right',
                sortable: true,
            },
            {
                id: 'displayQuestionnaire',
                label: 'Display Questionnaire',
                minWidth: 130,
            },
            {
                id: 'actions',
                label: '',
                minWidth: 150,
                align: 'right',
            },
        ];
    }, []);

    const handleChangeSortOrderSide = React.useCallback((field: string, side: Order) => {
        if (field === orderBy) {
            setOrderSide(side);
        } else {
            setOrderBy(field);
            setOrderSide(side);
        }
    }, [orderBy, orderSide]);

    const renderContent = React.useMemo(() => {
        if (isLoading) {
            return <CircularProgress />;
        }

        return (
            <TableComponent
                showPagination={true}
                columns={tableColumns}
                data={data || []}
                handleRowClick={handleRowClick}
                handleEdit={handleClickEditUser}
                handleDelete={handleDeleteUser}
                orderBy={orderBy}
                orderSide={orderSide}
                handleChangeSortOrderSide={handleChangeSortOrderSide}
            />
        );
    }, [data, isLoading, tableColumns, orderBy, orderSide]);

    const renderModalHead = React.useMemo(() => {
        return (
            <Grid container={true}>
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-around' }}>
                    <Typography
                        color="inherit"
                        variant="h6"
                        component="div"
                    >
                        Add User
                        <IconButton style={modalIconStyle} onClick={handleModalClose}>
                            <CloseIcon>Close</CloseIcon>
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
        );
    }, []);

    const renderModalBody = React.useMemo(() => {
        return (
            <Grid container={true} direction="column">
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                        value={username}
                        onChange={handleChangeUsername}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                    <TextField
                        type="password"
                        label="Password"
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                        value={password}
                        onChange={handleChangePassword}
                        autoComplete={'false'}
                        autoFocus={false}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                    <TextField
                        label="Role"
                        variant="outlined"
                        fullWidth={true}
                        size="small"
                        value={role}
                        onChange={handleChangeRole}
                    />
                </Grid>
                <Grid item={true} xs={12} sm={12} style={{ marginBottom: 16 }}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    value={displayQuestionnaire ? 'true' : 'false'}
                                    checked={displayQuestionnaire}
                                    onChange={handleToggleSwitcher}
                                />
                            }
                            label="Display Questionnaire"
                        />
                    </FormGroup>
                </Grid>
                <Grid container={true} spacing={4} justifyContent="center" alignItems="center">
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="error"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            fullWidth={true}
                            disableRipple={true}
                            onClick={handleModalConfirm}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [username, password, role, displayQuestionnaire]);

    const renderModal = React.useMemo(() => {
        return (
            <ModalComponent
                open={modalOpen}
                handleClose={handleModalClose}
                modalHead={renderModalHead}
                modalBody={renderModalBody}
            />
        );
    }, [modalOpen, renderModalHead, renderModalBody]);

    const renderTitle = React.useMemo(() => {
        return (
            <CustomToolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                </Typography>
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    align="right"
                >
                    <IconButton onClick={handleClickAddUser}>
                        <AddIcon />
                    </IconButton>
                    Add User
                </Typography>
            </CustomToolbar>
        );
    }, []);

    return (
        <CustomPaper elevation={4}>
            {renderTitle}
            {renderContent}
            {renderModal}
        </CustomPaper>
    );
};
