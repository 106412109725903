import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabProps {
  label: string;
  panel: React.ReactNode;
}

interface TabsProps {
  tabs: TabProps[];
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export const TabsComponent: React.FC<TabsProps> = ({ tabs }: TabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const renderTabs = React.useMemo(() => {
    return tabs.map((tab: TabProps, index: number) => {
      return (
        <Tab
          label={tab.label}
          key={`tab-${index}`}
          id={`tab-${index}`}
        />
      );
    });
  }, [value, tabs]);

  const renderTabPanels = React.useMemo(() => {
    return tabs.map((tab: TabProps, index: number) => {
      return (
        <TabPanel value={value} index={index} key={`tab-panel-${index}`}>
          {tab.panel}
        </TabPanel>
      );
    });
  }, [value, tabs]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {renderTabs}
        </Tabs>
      </Box>
      {renderTabPanels}
    </Box>
  );
}
