import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getApiPath } from 'helpers';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiPath(),
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');

      if (token) {
        headers.set('authorization', `Token ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Questionnaire', 'Question', 'Video', 'Tablet', 'Config', 'Image'],
  endpoints: () => ({}),
});
