import React from 'react';
import { Activity } from 'app/types';
import { TableComponent } from 'components/Table';
import { Toolbar, styled } from '@mui/material';

interface ActivitiesOfTabletProps {
    activities: Activity[];
}

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between',
}));

export const ActivitiesOfTablet: React.FC<ActivitiesOfTabletProps> = ({
    activities,
}: ActivitiesOfTabletProps) => {
    const tableColumns = React.useMemo(() => {
        return [
            {
                id: 'createdAt',
                label: 'Date',
                minWidth: 170,
            },
            {
                id: 'method',
                label: 'Method',
                minWidth: 120,
            },
            {
                id: 'action',
                label: 'Request',
                minWidth: 150,
            },
            {
                id: 'ip',
                label: 'IP',
                minWidth: 120,
            },
            {
                id: 'userAgent',
                label: 'UserAgent',
                minWidth: 170,
            },
        ];
    }, []);

    const renderContent = React.useMemo(() => {
        return (
            <TableComponent
                showPagination={true}
                columns={tableColumns}
                data={activities}
            />
        );
    }, [activities]);

    return (
        <React.Fragment>
            {renderContent}
        </React.Fragment>
    );
  };
