import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApiPath } from 'helpers';

export const addImage = createAsyncThunk(
  'addImage',
  async (data: any) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'authorization': `Token ${token}`,
        'content-type': 'multipart/form-data',
        'accept': 'application/json',
      },
    };

    const response = await axios.post(
      `${getApiPath()}admin/images`,
      data,
      config,
    );

    return response.data;
  },
);

interface ImagesState {
  images: any[];
  isLoading: boolean;
  error: any;
  shouldRefetch: boolean;
}

const initialState: ImagesState = {
  images: [] as any[],
  isLoading: false,
  error: null,
  shouldRefetch: false,
}

const imageSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addImage.pending, (state) => {
        state.isLoading = true;
        state.shouldRefetch = false;
      })
      .addCase(addImage.fulfilled, (state) => {
        state.isLoading = false;
        state.shouldRefetch = true;
      })
      .addCase(addImage.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

export default imageSlice.reducer;
