import { api } from 'app/api';
import { Questionnaire } from 'app/types';

export interface AddQuestionnaire {
  title: string;
  order: number;
  userId?: number;
}

export interface UpdateQuestionnaire {
  id: number;
  order?: number;
  title?: string;
}

export const questionnairesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionnaires: builder.query<Questionnaire[], void>({
      query: () => ({
        url: 'admin/questionnaires',
        method: 'GET',
      }),
      providesTags: ['Questionnaire'],
    }),
    getQuestionnaire: builder.query<Questionnaire, { id: string }>({
      query: (params) => ({
        url: `admin/questionnaires/${params.id}`,
        method: 'GET',
      }),
      providesTags: ['Questionnaire'],
    }),
    addQuestionnaire: builder.mutation<Questionnaire, AddQuestionnaire>({
      query: (params) => ({
        url: 'admin/questionnaires',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Tablet', 'Questionnaire'],
    }),
    updateQuestionnaire: builder.mutation<Questionnaire, UpdateQuestionnaire>({
      query: (params) => ({
        url: `admin/questionnaires/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Tablet', 'Questionnaire'],
    }),
    deleteQuestionnaire: builder.mutation<Questionnaire, { id: number }>({
      query: (params) => ({
        url: `admin/questionnaires/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tablet', 'Questionnaire'],
    }),
  }),
})

export const {
  useGetQuestionnairesQuery,
  useGetQuestionnaireQuery,
  useAddQuestionnaireMutation,
  useUpdateQuestionnaireMutation,
  useDeleteQuestionnaireMutation,
} = questionnairesApi;
