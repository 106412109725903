import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Paper } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  modalHead: any;
  modalBody: any;
}

export const ModalComponent: React.FC<ModalProps> = ({
  open,
  handleClose,
  modalHead,
  modalBody,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Paper elevation={3}>
        <Box sx={style}>
          {modalHead}
          {modalBody}
        </Box>
      </Paper>
    </Modal>
  );
}
