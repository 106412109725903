import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApiPath } from 'helpers';

export const addVideo = createAsyncThunk(
  'addVideo',
  async (data: any) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'authorization': `Token ${token}`,
        'content-type': 'multipart/form-data',
        'accept': 'application/json',
      },
    };
    const response = await axios.post(
      `${getApiPath()}admin/videos`,
      data,
      config,
    );

    return response.data;
  },
);

interface VideosState {
  videos: any[];
  isLoading: boolean;
  error: any;
  shouldRefetch: boolean;
}

const initialState: VideosState = {
  videos: [] as any[],
  isLoading: false,
  error: null,
  shouldRefetch: false,
}

const videoSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addVideo.pending, (state) => {
        state.isLoading = true;
        state.shouldRefetch = false;
      })
      .addCase(addVideo.fulfilled, (state) => {
        state.isLoading = false;
        state.shouldRefetch = true;
      })
      .addCase(addVideo.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

export default videoSlice.reducer;
