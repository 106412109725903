import React from 'react';
import { useDeleteImageMutation, useGetImagesQuery } from 'features/images/imagesApi';
import { Button, Grid,styled, Paper, Input, FormControl } from '@mui/material';
import { addImage } from 'features/images/imagesSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { dispatchAlert } from 'features/alerts/alertsSlice';

const CustomPaper = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    margin: theme.spacing(3),
}));

export const GeneralImages: React.FC = () => {
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const inputRef = React.useRef<any>(null);

  const shouldRefetch = useAppSelector(state => state.images.shouldRefetch);
  const { data, error, refetch } = useGetImagesQuery();
  const [deleteImage, resultDeleteImage] = useDeleteImageMutation();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
      refetch();
  }, [shouldRefetch]);

  React.useEffect(() => {
      if (error) {
          const { code, message } = (error as any).data;

          dispatch(
              dispatchAlert({
                  type: 'error',
                  code,
                  message,
              },
          ));
      }
  }, [error]);

  React.useEffect(() => {
      if (resultDeleteImage.error) {
          const { code, message } = (resultDeleteImage.error as any).data;

          dispatch(
              dispatchAlert({
                  type: 'error',
                  code,
                  message,
              },
          ));
      }
  }, [resultDeleteImage.error]);

  const handleSaveImage = React.useCallback(() => {
    const payload = new FormData();
		payload.append('file', imageFile);

		dispatch(addImage(payload));
  }, [imageFile]);

  const handleClearFileInput = React.useCallback(() => {
    setImageFile(null);
    deleteImage({ id: data[0].id });
    inputRef.current.value = null;
  }, [data]);

  const onFileChange = React.useCallback((event: any) => {
      setImageFile(event.target.files[0]);
  }, [data]);

  const uploadImage = React.useMemo(() => {
    return (
      <Grid container={true} spacing={3} style={{ padding: 32 }}>
        {data?.length > 0 && (
          <Grid item={true} xs={12} sm={12} style={{ textAlign: 'center' }}>
            <img src={data[0].file.url} alt="reserve-image" style={{ width: '676px' }} />
          </Grid>
        )}
        <Grid item={true} xs={12} sm={12} style={{ textAlign: 'center' }}>
          <FormControl>
            <Input
                id="file-image-input"
                type="file"
                onChange={onFileChange}
                inputProps={{ accept: '.jpg,.jpeg,.png'}}
                inputRef={inputRef}
            />
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12} sm={6}>
                <Button
                  variant="contained"
                  fullWidth={true}
                  onClick={handleClearFileInput}
                  color="error"
                  disabled={data?.length === 0}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item={true} xs={12} sm={6}>
                <Button
                  variant="contained"
                  fullWidth={true}
                  onClick={handleSaveImage}
                  disabled={data?.length > 0}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    );
  }, [data, imageFile]);

  return (
      <CustomPaper elevation={4}>
          {uploadImage}
      </CustomPaper>
  );
};
